import React, { createContext, useReducer } from "react"
import { LANGUAGE } from "./types"

export const globalStateContext = createContext()
export const globalDispatchContext = createContext()

const initialState = {
  language: "EN",
}

function reducer(state, action) {
  switch (action.type) {
    case LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      }
    }
    default:
      return state
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <globalStateContext.Provider value={state}>
      <globalDispatchContext.Provider value={dispatch}>
        {children}
      </globalDispatchContext.Provider>
    </globalStateContext.Provider>
  )
}

export default GlobalContextProvider
